/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resolve } from "../../../api/endPoints";
import {
  FACEBOOK,
  GOOGLE,
  INSTAGRAM,
  LINKEDIN_BUSINESS_PAGE,
  LINKEDIN_PERSONAL_ACCOUNT,
  TIKTOK_BUSINESS_PAGE,
  TIKTOK_PERSONAL_ACCOUNT,
  TWITTER,
  YOUTUBE,
} from "../../../config/constants";
import axiosClient from "../../../utils/axios";
interface LocationDetails {
  uuid: string;
  slug: string;
  image: string;
  name: string;
  dashboard_link: string;
  demo: boolean;
  show_data_import_info: boolean;
  sync_active: boolean;
  platforms: {
    facebook: boolean;
    instagram: boolean;
    google: boolean;
    twitter: boolean;
    linkedin_personal_account: boolean;
    linkedin_business_page: boolean;
    youtube: boolean;
    tiktok_personal_account: boolean;
    tiktok_business_page: boolean;
  };
  platforms_information: any;
  isLoading: boolean;
}
const initialState: LocationDetails = {
  uuid: "",
  slug: "",
  image: "",
  name: "",
  show_data_import_info: false,
  dashboard_link: "",
  demo: false,
  sync_active: false,
  platforms: {
    facebook: false,
    instagram: false,
    google: false,
    twitter: false,
    linkedin_personal_account: false,
    linkedin_business_page: false,
    youtube: false,
    tiktok_personal_account: false,
    tiktok_business_page: false,
  },
  platforms_information: {},
  isLoading: true,
};

const slice = createSlice({
  name: "locationDetails",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setLocationInfo: (state, action: PayloadAction<any>) => {
      state.uuid = action.payload.data.uuid;
      state.slug = action.payload.data.slug;
      state.sync_active = action.payload.data.sync_active;
      state.show_data_import_info = action.payload.data.show_data_import_info;
      state.image = action.payload.data.image;
      state.name = action.payload.data.name;
      state.dashboard_link = "";
      state.demo = action.payload.data.load_demo_data;
      state.platforms = {
        [FACEBOOK]: state.demo || action.payload.data.platforms[FACEBOOK],
        [INSTAGRAM]: state.demo || action.payload.data.platforms[INSTAGRAM],
        [GOOGLE]: state.demo || action.payload.data.platforms[GOOGLE],
        [TWITTER]: state.demo || action.payload.data.platforms[TWITTER],
        [LINKEDIN_PERSONAL_ACCOUNT]:
          state.demo ||
          action.payload.data.platforms[LINKEDIN_PERSONAL_ACCOUNT],
        [LINKEDIN_BUSINESS_PAGE]:
          state.demo || action.payload.data.platforms[LINKEDIN_BUSINESS_PAGE],
        [YOUTUBE]: state.demo || action.payload.data.platforms[YOUTUBE],
        [TIKTOK_PERSONAL_ACCOUNT]:
          state.demo || action.payload.data.platforms[TIKTOK_PERSONAL_ACCOUNT],
        [TIKTOK_BUSINESS_PAGE]:
          state.demo || action.payload.data.platforms[TIKTOK_BUSINESS_PAGE],
      };
      state.platforms_information = action.payload.data.platforms_information;
    },
  },
});

export default slice.reducer;

export function getLocationDetails(location: string) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    const url = resolve("location_details", { locationslug: location }, {});
    const response = await axiosClient.get(url);
    dispatch(slice.actions.setLocationInfo({ data: response.data }));
    dispatch(slice.actions.stopLoading());
  };
}
